if (!window.addEventListener) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.attachEvent("load", function () {
        document.body.innerHTML +=
            '<div class="upgrade-browser">' +
            "<p>It looks like you are using an older browser. For coManage.com to work right, you should upgrade</p>" +
            '<p>We recommend <a href="http://chrome.google.com/">Google Chrome</a> or <a href="http://firefox.com">Firefox</a></p>' +
            "</div>";
    });
}
