const NAVBAR_KEY = "comanage--navbar-state";

const navbar = document.getElementById("navbar");

if (navbar && !document.documentElement.hasAttribute("data-testing")) {
    navbar.addEventListener("shown.bs.collapse", (e) => {
        if (e.target instanceof Element) {
            window.localStorage.setItem(NAVBAR_KEY, e.target.id);
        }
    });
    navbar.addEventListener("hide.bs.collapse", (e) => {
        if (e.target instanceof Element) {
            window.localStorage.setItem(NAVBAR_KEY, null);
        }
    });

    const state = window.localStorage.getItem(NAVBAR_KEY);
    const openElement = state ? document.getElementById(state) : null;

    if (openElement) {
        openElement.classList.add("show");
        document
            .querySelector(`a[data-bs-toggle="collapse"][data-bs-target="#${openElement.id}"]`)
            .setAttribute("aria-expanded", "true");
    }
}
